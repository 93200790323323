@import "components/ui/_variables";

.visitor-footer {
  width: 100%;
  height: fit-content;
  position: absolute;

  background-color: $black-color;

  &__link {
    text-decoration: none !important;
  }

  &-cp {
    color: $white-color;
    text-align: center;
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 2rem;
    span {
      color: $secondary-color;
    }
  }

  &--inner {
    width: 90%;
    border-top: 0.1px solid $primary-color;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
    color: $white-color;
    .visitor-footer__block {
      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 2.5rem;
        letter-spacing: 0.1px;
        color: $white-color;
        width: fit-content;
        margin-bottom: 1rem;
        @media screen and (max-width: $medium-screen) {
          line-height: 2rem;
          font-size: 1rem;
        }
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 2rem;
        letter-spacing: 0.2px;
        color: $white-muted-color;
        cursor: pointer;
        @media screen and (max-width: $medium-screen) {
          line-height: 2rem;
          font-size: 0.9rem;
        }
      }
    }
    .visitor-footer__block:first-child {
      margin-top: 3rem;
    }
    .visitor-footer__block:nth-child(2) {
      div {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3rem;
        @media screen and (max-width: $medium-screen) {
          margin-right: unset;
        }
      }
    }
    .visitor-footer__block:last-child {
      div {
        width: fit-content;
        margin-left: auto;
        margin-top: 3rem;

        .visitor-footer__icon {
          margin-right: 1rem;
          border: 0.5px solid $secondary-color;
          padding: 0.3rem;
          border-radius: 50%;
          width: 3.5rem;
        }

        @media screen and (max-width: $medium-screen) {
          margin-right: unset;
          margin-left: unset;
          margin-top: 0rem;
        }
      }
    }
  }
}
