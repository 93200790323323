@import "components/ui/_variables";

.download-app {
  height: fit-content;
  width: 100%;
  background-color: $black-color;
  h1 {
    font-weight: 400;
    font-size: 40px;
    line-height: 62px;
    letter-spacing: 0.2px;
    color: #ffffff;
    text-align: center;
    margin-top: 8rem;
    @media screen and (max-width: $medium-screen) {
      font-size: 2rem;
    }
  }
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;
    margin-top: 2rem;
    @media screen and (max-width: $medium-screen) {
      font-size: 0.9rem;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1rem;
    }
  }
  .download-app__button {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    button {
      width: 100%;
      font-size: 14px;
    }
    @media screen and (max-width: $medium-screen) {
      display: block;
      margin-top: 1rem;
      text-align: center;
      button {
        margin-top: 1rem;
      }
    }
  }
  .download-app__image {
    text-align: center;
    margin-top: 6rem;
    // margin-bottom: 2rem;
    img {
      height: fit-content;
      width: fit-content;
      @media screen and (max-width: $medium-screen) {
        height: 90%;
        width: 70%;
      }
    }
  }
}
