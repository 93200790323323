@import "components/ui/__variables.scss";

.overview-summary-card {
  width: 100%;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  div {
    display: block;
    text-align: center;
    line-height: 1.5rem;
    .overview-icon {
      color: $gray-color;
      font-size: 1.2rem;
      margin-top: 1rem;
    }
    h1 {
      color: $gray-color;
      font-size: x-large;
    }
    p {
      color: $black-color;
      font-weight: 500;
      font-size: smaller;
    }
  }
}
