@import "components/ui/_variables";

.admin-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  &__card {
    width: 100%;
    max-width: 550px;
    border-radius: 10px;
    margin-top: 5rem;
    border-color: $white-muted-color;
  }

  &-form {
    &__input {
      margin-bottom: 2rem;
    }
    &__button {
      margin-top: 1rem;
      margin-top: 1rem;
      width: 100% !important;
      color: $primary-muted-color;
    }
  }

  &-link {
    &__forgot {
      margin-top: 20px;
      float: left;
      font-size: smaller;
      text-decoration: none;
    }
    &__login {
      margin-top: 20px;
      float: right;
      font-size: smaller;
    }
    &__started {
      text-decoration: none;
    }
  }

  .login-link {
    margin-top: 20px;
    font-size: smaller;
    text-align: center;
    &__signin {
      text-decoration: none;
    }
  }
}