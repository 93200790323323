@import "components/ui/_variables";

.testimonials {
  height: fit-content;
  width: 100%;
  //   background-color: red;
  margin-bottom: -2rem;

  &__list {
    margin-top: 2rem;
    width: 90%;
    display: flex;
    gap: 2rem;
    // align-items: center;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: $medium-screen) {
      display: block;
      width: 90%;
    }
  }
  &__card {
    color: $black-color !important;
    width: 100%;
    padding: 1rem;
    // height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-radius: $border-radius;
    background-color: $white-muted-color;
    &--user {
      background-color: $gray-muted-color;
      border-radius: 100%;
      border: 1px solid $white-color;
      padding: 1.2rem;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      //   color: $black-color;
    }
    text-align: center;
    h4 {
      color: $secondary-color;
    }
    p {
      font-size: 0.8rem;
    //   color: $gray-muted-color;
      line-height: 1.8rem;
    }
  }
}
