.permissions {
  &__list {
    // display: grid;
    // gap: 20px;
    div {
      display: inline-flex;
      margin-right: 1rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
