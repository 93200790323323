@import "components/ui/_variables";

.landing-page {
  height: fit-content;
  width: 100%;
  // position: absolute;
  background-color: $black-color;
  //   position: absolute;
  &__title {
    font-weight: 400;
    font-size: 40px;
    line-height: 62px;
    letter-spacing: 0.2px;
    color: #ffffff;
    text-align: center;
    margin-top: 4rem;
    @media screen and (max-width: $medium-screen) {
      font-size: 2rem;
    }
  }
  &__description {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #ffffff;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: $medium-screen) {
      font-size: 0.9rem;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &--hero {
    height: fit-content;
    width: 100%;
    margin-top: 9rem;
    @media screen and (max-width: $medium-screen) {
      margin-top: 8rem;
    }
    h1 {
      font-weight: 500;
      font-size: 50px;
      line-height: 88px;
      text-align: center;
      letter-spacing: 0.2px;
      color: transparent;
      -webkit-text-stroke: 1px $white-color;
      @media screen and (max-width: $medium-screen) {
        font-size: 1.5rem;
        line-height: 3rem;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
      span {
        -webkit-text-stroke: 1px $secondary-color;
      }
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #ffffff;
      margin-top: 2rem;
      width: 40%;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: $medium-screen) {
        font-size: 0.8rem;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
      }
    }
    .hero__button {
      display: flex;
      justify-content: center;
      gap: 1rem;
      margin-top: 2rem;
      @media screen and (max-width: $medium-screen) {
        display: block;
        margin-top: 1rem;
        text-align: center;
        button {
          margin-top: 1rem;
        }
      }
    }
    .hero__image {
      text-align: center;
      margin-top: 6rem;
      margin-bottom: 2rem;
      img {
        height: fit-content;
        width: fit-content;
        @media screen and (max-width: $medium-screen) {
          height: 90%;
          width: 70%;
        }
      }
    }
  }
  &--features {
    height: fit-content;
    width: 100%;
    &__list {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      color: $white-color;
      margin-top: 2rem;
      @media screen and (max-width: $medium-screen) {
        width: 90%;
      }
      .app-feature-col {
        margin-left: auto;
        margin-right: auto;
      }
      .app-feature {
        display: flex;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        height: 15rem;
        border: 0.1px solid $primary-color;
        border-radius: $border-radius;
        padding: 1rem;
        align-items: center;

        &__inner {
          display: flex;
          @media screen and (max-width: $medium-screen) {
            display: block;
            width: 100%;
          }
        }

        .features-icon {
          color: $secondary-color;
          margin-top: 0.5rem;
          margin-right: 1rem;
          @media screen and (max-width: $medium-screen) {
            text-align: center;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0rem;
          }
        }
        h4 {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.1px;
          color: $white-color;
          @media screen and (max-width: $medium-screen) {
            text-align: center;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
          }
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;
          margin-bottom: 0rem;
          color: $gray-color;
          @media screen and (max-width: $medium-screen) {
            font-size: 0.9rem;
            text-align: center;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
  &--demo {
    .demo__video {
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      text-align: center;
      margin-top: 1rem;
      iframe {
        width: 30rem;
        height: 25rem;
        border-radius: $border-radius;
        // border: 0.1px solid $primary-color;
        margin-bottom: 2rem;
        @media screen and (max-width: $medium-screen) {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &--contactus {
    .contact {
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      text-align: center;
      margin-top: 1rem;

      @media screen and (max-width: $medium-screen) {
        width: 90%;
        height: 100%;
      }
    }
  }

  &--services {
    height: fit-content;
    width: 100%;
    &__list {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      color: $white-color;
      margin-top: 2rem;

      display: flex;
      overflow-x: scroll;
      // gap: 2rem;

      @media screen and (max-width: $medium-screen) {
        width: 100%;
      }
      .app-services-col {
        margin-left: auto;
        margin-right: auto;
      }
      .app-service {
        padding: 1rem;
        text-align: center;
        // border: 0.1px solid $gray-color;
        border-radius: $border-radius;
        margin-bottom: 2rem;
        .img {
          border-radius: $border-radius;
          flex-shrink: 0;
          width: 30vw;
          height: 50vh;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          @media screen and (max-width: $medium-screen) {
            width: 70vw;
            height: 40vh;
          }
        }
        h5 {
          // font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;
          margin-bottom: 0rem;
          color: $secondary-color;
          margin-top: 2rem;
          font-style: italic;
          @media screen and (max-width: $medium-screen) {
            font-size: 0.9rem;
            text-align: center;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
          }
        }
        @media screen and (max-width: $medium-screen) {
          display: block;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 0px;
}