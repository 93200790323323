@import "components/ui/_variables";

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__card {
    width: 100%;
    max-width: 550px;
    border-radius: 10px;
    margin-top: 5rem;
    border-color: $white-muted-color;
    @media screen and (max-width: $small-screen) {
      display: flex;
      justify-content: center;
      margin-top: 0rem;
    }
  }
  &__text {
    display: none;
  }

  &-form {
    &__input {
      margin-bottom: 1rem;
    }
    &__button {
      margin-top: 1rem;
      width: 100% !important;
      color: $primary-muted-color;
    }
  }
  &-link {
    &__forgot {
      margin-top: -20px;
      float: left;
      font-size: smaller;
      text-decoration: none;
      display: inline;
    }
    &__forgotpass {
      display: none;
      float: right;
    }
    &__login {
      margin-top: 20px;
      text-align: right;
      font-size: smaller;
      display: inline;
    }
    &__started {
      text-decoration: none;
    }
    &__mobile {
      margin-top: 30px;
      display: none;
    }
  }

  .login-link {
    margin-top: 20px;
    font-size: smaller;
    text-align: center;
    &__signin {
      text-decoration: none;
    }
  }
}
