@import "components/ui/_variables";

.auth {
  @media screen and (max-width: $small-screen) {
    h1 {
      text-align: left;
      margin-top: 5rem;
      font-size: larger;
      color: $primary-muted-color;
    }
    &__card {
      border: none;
    }
    &__text {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $gray-color;
      opacity: 0.8;
      display: inline;
    }

    &-form {
      @media screen and (max-width: $small-screen) {
        &__input {
          display: block;
        }
      }

      &__button {
        background: #3163ad;
        border-radius: 4px;
      }
    }
    &-link {
      &__forgot {
        display: none;
      }
      &__forgotpass {
        display: inline;
        text-decoration: none;
        color: $secondary-color;
        font-size: smaller;
      }
      &__login {
        display: none;
      }
      &__mobile {
        color: $secondary-color;
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        display: inline;
      }
      &__text {
        font-weight: 800;
        font-size: 12px;
        line-height: 14px;
        color: $primary-muted-color;
        text-decoration: none;
      }
    }
  }
}
