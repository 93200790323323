@import "components/ui/_variables";

.user-card {
  align-content: center;
  width: 100%;
  height: 22rem;
  border-radius: 10px;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0px;
  display: inline-block;
  justify-content: center;
  text-align: center;
  align-items: center;
  overflow: hidden;
  &__image {
    background-color: $white-muted-color;
    width: 100%;
    height: 6.5rem;
    display: flex;
    justify-content: center;
    .custom-icon {
      height: 100%;
    }
    &-container {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-top: 3.5rem;
      background-color: $gray-color;
    }

    &-avatar {
      text-align: center;
      color: white;
      font-size: 80px;
      // height: 4rem;
      border-radius: 50%;
      border: 1px solid $gray-color;
    }
  }
  h1 {
    margin-top: 3rem;
    color: $black-color;
    font-size: large;
    justify-items: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    .user-indicator {
      font-size: 0.5rem;
      font-weight: bold;
      text-transform: uppercase;
      color: $primary-color;
      margin-left: 0.5rem;
    }
  }
  p {
    max-width: 80%;
    color: $black-color;
    font-weight: 500;
    font-size: smaller;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 40px;
    line-height: 20px;
  }
}
