@import "components/ui/_variables";

.personal-details {
  form {
    .personal-details__input {
      margin-bottom: 1rem;
    }
    .personal-details__button {
      margin-top: 1rem;
    }
  }
  .profile-pic {
    margin-bottom: 1rem;
    &__image {
      width: 100%;
      height: 15rem;
      &-avatar {
        font-size: 10rem;
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
        color: $gray-color;
      }
    }
    &__select {
      margin-top: 12rem;
      float: right;
    }
  }
}
