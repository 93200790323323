@import "components/ui/_variables";
.admin-requests {
  &-search {
    width: 25rem;
    margin-bottom: 1rem;
  }
  .custom-table {
    display: none !important;
    table {
      table-layout: initial !important;
      display: none !important;
    }
  }
  &--table {
    td:first-child {
      width: 100% !important;
    }
    td:nth-last-child(1) {
      text-align: center !important;
    }
    th:nth-last-child(1) {
      text-align: center !important;
    }
  }
}
