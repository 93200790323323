@import "components/ui/_variables";
.visitor-header {
  background-color: $black-color;
  height: 6rem;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white-color;
  &--inner {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    .visitor-header__col {
      img {
        width: 5rem;
        height: 5rem;
        border-radius: $border-radius;
      }
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: $white-color;
        cursor: pointer;
      }
    }
    .visitor-header__col:nth-child(2) {
      width: fit-content;
      text-align: center;
      margin-top: 0.8rem;
      @media screen and (max-width: $medium-screen) {
        // text-align: left;
      }
    }
    .visitor-header__col:last-child {
      width: fit-content;
      text-align: right;
      margin-top: 1.5rem;
      span {
        color: $white-color;
        border: 0.01px solid $gray-color;
        padding: 0.5rem;
        font-size: 0.9rem;
        border-radius: $border-radius;
      }
    }
  }
}
