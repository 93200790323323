.customers {
  &-heading {
    &--upper-filter {
      display: flex;
      gap: 1rem;
      &--search {
        height: 3.4rem !important;
        margin-top: 0.5rem;
      }
    }
  }
}
