@import "components/ui/_variables";

.privacy-page {
  height: fit-content;
  width: 100%;
  //   position: absolute;
  background-color: $black-color;
  margin-bottom: -1rem;
  &__content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    height: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
    p {
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 30px;
      letter-spacing: 0.2px;
      color: $black-color;
      padding: 1rem 1.5rem;
      margin-top: 0rem;
      margin-bottom: 0rem;
      @media screen and (max-width: $medium-screen) {
        font-size: 0.8rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    h4 {
      font-size: small;
      padding: 1rem 1.5rem;
      margin-top: 0rem;
      margin-bottom: 0rem;
      font-weight: bold;
    }
  }
  h1 {
    font-weight: 400;
    font-size: 40px;
    line-height: 62px;
    letter-spacing: 0.2px;
    color: #ffffff;
    margin-top: 8rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: $medium-screen) {
      font-size: 1.5rem;
    }
  }
}
