@import "components/ui/_variables";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

main {
  height: 100%;
  width: 100%;
  transition: 0.3s opacity;
  animation: fadeIn 5s;
}
