@import "~react-pro-sidebar/dist/scss/styles.scss";

@import "components/ui/_variables";

.admin-layout {
  .logout {
    color: $cancel-color !important;
  }
  .sidebar {
    z-index: 1000;
    min-height: 100vh;
    // margin-top: -5rem;
    position: absolute;
    z-index: 10;
    border-right: 0.063rem solid #ecebf5;
    .pro-sidebar-layout {
      // margin-top: 5rem;
      background-color: white !important;
      color: $gray-color !important;
      .pro-item-content {
        font-size: 1rem !important;
        text-transform: capitalize;
        line-height: 2em !important;

        &:hover {
          color: rgba(11, 21, 33, 0.8) !important;
        }
      }
      .pro-arrow-wrapper {
        margin-right: 1rem;
      }
      .pro-inner-list-item {
        // font-weight: 600;
        color: #636365 !important;
        margin-left: 1.5rem;
      }
      .pro-icon-wrapper {
        background-color: white !important;
        // margin-left: 1rem;
      }
    }
    .logout {
      color: $cancel-color;
    }
  }

  header {
    height: 5rem;
    width: 100%;
    background-color: white;
    border-bottom: 0.063rem solid #ecebf5;
    .header-menu-close {
      display: flex;
      position: absolute;
      margin-top: 2rem;
      margin-left: 2rem;
      cursor: pointer;
      transition: 0.5s;
    }
    .header-menu {
      display: flex;
      position: absolute;
      margin-top: 0.75rem;
      margin-left: 2rem;
      border: 0.063rem solid $primary-color;
      height: 3.5rem;
      width: 13rem;
      border-radius: 0.5rem;
      align-items: center;
      transition: 0.5s;
      cursor: pointer;
      div {
        display: flex;
        width: 100%;
        align-items: center;
        .avatar {
          font-size: 2.5rem;
          color: #636365;
          width: fit-content;
          margin-left: 0.5rem;
        }
        .header-menu-user {
          margin-left: 0.5rem;
          color: $black-color;
        }
        .menu {
          width: fit-content;
          margin-right: 0.5rem;
          color: $cancel-color;
          &-open {
            cursor: pointer;
            transition: 0.5s;
          }
          &-close {
            cursor: pointer;
            transition: 0.5s;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.admin-main {
  max-width: 100%;
  min-height: 100vh;
  margin-left: 7em;
  margin-right: 2rem;
  // margin-top: -100vh;
  transition: 0.3s opacity;
  animation: fadeIn 5s;
}

.admin-main-expand {
  margin-left: 190px;
}

.admin-main-inner {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
