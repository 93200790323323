@import "components/ui/_variables";

.create-admin-user {
  form {
    .create-admin-input {
      margin-bottom: 1rem;
    }
    .create-admin-button {
      width: 100%;
      margin-top: 1rem;
      height: 3rem;
    }
  }
}
