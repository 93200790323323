@import "components/ui/_variables";

.view-user__title {
  h3 {
    color: $black-color;
  }
  p {
    color: $primary-color;
    text-transform: uppercase;
    font-size: smaller;
  }
}

.view-user-profile {
  &-card {
    width: 12rem;
    height: 12rem;
    background-color: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: $white-muted-color;
    &-icon {
      font-size: 10rem;
      color: $white-muted-color;
    }
  }
  &-info {
    p {
      font-weight: 500;
      font-size: smaller;
      margin-top: 1rem;
      line-height: 20px;
    }
    .view-user-profile-section {
      width: 100%;
      height: fit-content !important;
      margin-top: 2rem;
      border: none;
      padding: 0;
      h6 {
        margin-top: 0rem !important;
        margin-bottom: 0rem;
        font-weight: 500;
      }
      p {
        color: $gray-color;
        font-weight: 400;
        font-size: smaller;
        margin-top: 1rem;
        line-height: 20px;
      }
    }
  }
  &-actions {
    margin-top: 1rem;
    width: 100%;
    .view-user-profile__disable_btn {
      width: 100%;
    }
    .view-user-profile__delete_btn {
      border-color: $cancel-color !important;
      width: 100%;
      background-color: $white-color;
      color: $cancel-color;
    }
  }
}
