@import "../_variables";

.custom-table {
  box-sizing: border-box;

  table {
    // table-layout: fixed;
    width: 100% !important;
  }

  tbody {
    font-size: 0.85rem;
    td {
      border-bottom: 0.5px solid $gray-muted-color;
      border: 0.5px solid $white-muted-color;
      padding: 1rem;
      text-transform: capitalize;
    }
  }

  thead {
    background-color: $white-color;
    color: $black-color;
    th {
      font-weight: 600;
      border: 0.5px solid $white-muted-color;
      padding: 1rem !important;
    }
    tr {
      border-bottom: 0.5px solid $gray-muted-color;
    }
  }
}
