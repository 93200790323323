@import "components/ui/_variables";
.transactions {
  &-search {
    width: 25rem;
    margin-bottom: 1rem;
  }

  &--table {
    td:nth-last-child(1) {
      text-align: center !important;
    }
    th:nth-last-child(1) {
      text-align: center !important;
    }
  }
}
