@import "components/ui/__variables.scss";

.category-card {
  width: 100%;
  height: 15rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: $gray-color;
    font-size: x-large;
  }
  p {
    color: $black-color;
    font-weight: 500;
    font-size: 0.9rem;
  }
  &-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    &__edit {
      width: 8rem !important;
      height: 2.5rem !important;
    }
    &__delete {
      background-color: $white-color !important;
      width: 8rem !important;
      margin-left: 2rem;
      height: 2.5rem !important;
      border-color: $cancel-color !important;
      color: $cancel-color !important;
      font-weight: 400 !important;
    }
  }
}
