.admins {
  &-heading {
    display: flex;
    justify-content: space-between;
  }
  &-search {
    width: 25rem;
    margin-bottom: 1rem;
  }
}
